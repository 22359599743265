import * as React from "react";
import { Row, Col } from "react-grid-system";
import HcpLayout from "../../components/hcp/layout";
import { Seo } from "../../components/seo";
import "./living-with-narcolepsy.scss";
import { Link } from "gatsby";
import useDeviceSize from "../../hooks/use-device-size";
// import { AiOutlineDownload } from "react-icons/ai";
import TabAccordion from "../../components/tabAccordion";
import TabAccordionList from "../../components/tabAccordion/tabAccordionList";
import TabAccordionCustomTab from "../../components/tabAccordion/tabAccordionCustomTab";
import TabAccordionPanel from "../../components/tabAccordion/tabAccordionPanel";
import everyoneImg from "../../images/living-with-narcolepsy/infographics-everyone.svg";
import infogrphicsImg from "../../images/living-with-narcolepsy/infographics-2-3.svg";
import infogrphicsNarcoplesyImg from "../../images/living-with-narcolepsy/infographics-US-narcolepsy.webp";
import infogrphicsType1 from "../../images/living-with-narcolepsy/infographics-type-1.svg";
import infogrphicsType2 from "../../images/living-with-narcolepsy/infographics-type-2.svg";
import flightingIcon from "../../images/living-with-narcolepsy/symptom-icons-fight.svg";
import clockIcon from "../../images/living-with-narcolepsy/symptom-icons-unrefreshed.svg";
import inactiveIcon from "../../images/living-with-narcolepsy/symptom-icons-inactive-situations.svg";
import kneesIcon from "../../images/living-with-narcolepsy/symptom-icons-knees.svg";
import handsIcon from "../../images/living-with-narcolepsy/symptom-icons-hand-weakness.svg";
import jawIcon from "../../images/living-with-narcolepsy/symptom-icons-jaw-sagging.svg";
import SocialIconBlock from "../../components/socialIconBlock";
// import StickySupport from "../../components/sticky-support";
import { TfiAngleRight } from "react-icons/tfi";
import resourcesImg from "../../images/living-with-narcolepsy/cta-personal-stories.webp";
import resourcesMobileImg from "../../images/living-with-narcolepsy/cta-personal-stories-mobile.webp";
import tipsIcon from "../../images/living-with-narcolepsy/tips-icon.webp";
import bannerImg from "../../images/living-with-narcolepsy/hero-narcolepsy.webp";
import bannerImgMobile from "../../images/living-with-narcolepsy/hero-narcolepsy-mobile.webp";

const LivingWithNarcolepsy = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout
            pageClass="living-with-narcolepsy"
            noStickyHCPLocatorCTA={true}
        >
            <div className="top-heading">
                <div className="centered-content">
                    <h2 className="blue">
                        Excessive Daytime Sleepiness and Cataplexy
                    </h2>
                </div>
            </div>
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover narcolepsy">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                alt="Four people living with narcolepsy with arms around each other looking into the distance"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                alt="Four people living with narcolepsy with arms around each other looking into the distance"
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* <StickySupport /> */}
            <section className="tab-accordion-block inner-first">
                <div className="eds-block__wrap">
                    <div className="centered-content">
                        <div className="tab-block">
                            <TabAccordion>
                                <TabAccordionList>
                                    <TabAccordionCustomTab>
                                        Narcolepsy
                                    </TabAccordionCustomTab>
                                    <TabAccordionCustomTab>
                                        EDS
                                    </TabAccordionCustomTab>
                                    <TabAccordionCustomTab>
                                        Cataplexy
                                    </TabAccordionCustomTab>
                                </TabAccordionList>

                                <TabAccordionPanel id="tab1">
                                    <div className="narcolepsy-content">
                                        <div className="centered-content">
                                            <h1 className="blue font-40">
                                                Living With Narcolepsy
                                            </h1>
                                            <h2>
                                                The two most common symptoms of
                                                narcolepsy are excessive daytime
                                                sleepiness (EDS) and cataplexy
                                            </h2>
                                            <span className="title-tag font-21 asap">
                                                You are not alone
                                            </span>
                                            <Row className="row">
                                                <Col
                                                    lg={4}
                                                    md={6}
                                                    className="col border-right-col"
                                                >
                                                    <div className="card-symptom">
                                                        <div className="card-symptom__media custom-media">
                                                            <img
                                                                src={
                                                                    everyoneImg
                                                                }
                                                                alt="Icon of 3 people filled in depicting everyone"
                                                            />
                                                        </div>
                                                        <div className="card-symptom__info">
                                                            <span className="title font-21 asap">
                                                                Everyone
                                                            </span>
                                                            <p>
                                                                with narcolepsy
                                                                has{" "}
                                                                <strong>
                                                                    {" "}
                                                                    EDS
                                                                </strong>
                                                                , the inability
                                                                to stay awake
                                                                and alert during
                                                                the day or a
                                                                persistent
                                                                feeling of
                                                                tiredness
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    lg={4}
                                                    md={6}
                                                    className="col border-right-col"
                                                >
                                                    <div className="card-symptom">
                                                        <div className="card-symptom__media custom-media">
                                                            <img
                                                                src={
                                                                    infogrphicsImg
                                                                }
                                                                alt="Icon depicting 2 of 3 people filled in "
                                                            />
                                                        </div>
                                                        <div className="card-symptom__info">
                                                            <span className="title font-21 asap">
                                                                Nearly 2 out of
                                                                3
                                                            </span>
                                                            <p>
                                                                people with
                                                                narcolepsy may
                                                                also have{" "}
                                                                <strong>
                                                                    cataplexy
                                                                </strong>
                                                                , the sudden
                                                                onset of weak or
                                                                paralyzed
                                                                muscles, usually
                                                                brought on by
                                                                strong emotions
                                                                or certain
                                                                situations
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    lg={4}
                                                    md={6}
                                                    className="col"
                                                >
                                                    <div className="card-symptom">
                                                        <div className="card-symptom__media">
                                                            <img
                                                                src={
                                                                    infogrphicsNarcoplesyImg
                                                                }
                                                                alt="Icon of US map filled in"
                                                            />
                                                        </div>
                                                        <div className="card-symptom__info">
                                                            <span className="title font-21 asap">
                                                                Approximately
                                                                165,000
                                                            </span>
                                                            <p>
                                                                people in the
                                                                United States
                                                                are living with
                                                                narcolepsy
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h2>Types of narcolepsy</h2>
                                            <span className="title-tag title-tag--alt">
                                                There are two types of
                                                narcolepsy: narcolepsy type 1
                                                and narcolepsy type 2.
                                            </span>
                                            <Row className="row">
                                                <Col
                                                    lg={6}
                                                    md={6}
                                                    className="col border-right-col"
                                                >
                                                    <div className="card-symptom">
                                                        <div className="card-symptom__media type-media">
                                                            <img
                                                                src={
                                                                    infogrphicsType1
                                                                }
                                                                alt="Icon of circle with Type 1 inside"
                                                            />
                                                        </div>
                                                        <div className="card-symptom__info">
                                                            <p>
                                                                Narcolepsy{" "}
                                                                <strong>
                                                                    with
                                                                    cataplexy
                                                                </strong>{" "}
                                                                is sometimes
                                                                called
                                                                narcolepsy{" "}
                                                                <nobr>
                                                                    type 1
                                                                </nobr>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    md={6}
                                                    className="col"
                                                >
                                                    <div className="card-symptom">
                                                        <div className="card-symptom__media type-media">
                                                            <img
                                                                src={
                                                                    infogrphicsType2
                                                                }
                                                                alt="Icon of circle with Type 2 inside"
                                                            />
                                                        </div>
                                                        <div className="card-symptom__info">
                                                            <p>
                                                                Narcolepsy{" "}
                                                                <strong>
                                                                    without
                                                                    cataplexy
                                                                </strong>{" "}
                                                                is sometimes
                                                                called
                                                                narcolepsy{" "}
                                                                <nobr>
                                                                    type 2
                                                                </nobr>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h2>
                                                People may experience excessive
                                                daytime sleepiness (EDS) and
                                                cataplexy in different ways
                                            </h2>
                                        </div>
                                    </div>
                                </TabAccordionPanel>

                                <TabAccordionPanel id="tab2">
                                    <div className="centered-content">
                                        <div className="eds-content">
                                            <h3 className="black">
                                                What can EDS feel like?
                                            </h3>
                                            <p>
                                                People living with narcolepsy{" "}
                                                <strong>
                                                    may experience their EDS in
                                                    different ways
                                                </strong>
                                                , like:
                                            </p>
                                            <ul className="icon-list">
                                                <li>
                                                    <div className="icon">
                                                        <img
                                                            src={flightingIcon}
                                                            alt="Icon depicting excessive daytime sleepiness at a desk reading a book"
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-20 asap asap-bold">
                                                            Fighting to stay
                                                            awake
                                                        </span>
                                                        <p>
                                                            People may fight to
                                                            stay awake and alert
                                                            throughout the day
                                                            or fall asleep
                                                            without warning.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <img
                                                            src={clockIcon}
                                                            alt="Alarm clock icon"
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-20 asap asap-bold">
                                                            Feeling sleepy soon
                                                            after waking up
                                                        </span>
                                                        <p>
                                                            Some people may wake
                                                            up feeling somewhat
                                                            refreshed but feel
                                                            sleepy again in a
                                                            few hours.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <img
                                                            src={inactiveIcon}
                                                            alt="Icon depicting excessive daytime sleepiness while watching television"
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-20 asap asap-bold">
                                                            Feeling sleepy in
                                                            less active
                                                            situations
                                                        </span>
                                                        <p>
                                                            People may feel
                                                            especially sleepy in
                                                            situations that
                                                            require no active
                                                            participation, such
                                                            as watching TV or
                                                            riding in a car.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="cta-block">
                                                <span className="font-21 asap asap-bold">
                                                    Download a questionnaire to
                                                    assess your EDS so you can
                                                    talk with your healthcare
                                                    provider about how your
                                                    symptoms may be affecting
                                                    you.{" "}
                                                </span>
                                                <a
                                                    href="/pdf/Measure-excessive-daytime-sleepiness-epworth-scale.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="btn btn-primary btn-primary--md dwn-btn"
                                                >
                                                    Assess your EDS
                                                    <svg
                                                        id="download_arrow"
                                                        data-name="download arrow"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xlink="http://www.w3.org/1999/xlink"
                                                        width="21"
                                                        height="22"
                                                        viewBox="0 0 21 22"
                                                    >
                                                        <defs>
                                                            <clipPath id="clip-path">
                                                                <path
                                                                    id="Clip_4"
                                                                    data-name="Clip 4"
                                                                    d="M0,0H2V16H0Z"
                                                                    fill="none"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                        <path
                                                            id="Fill_1"
                                                            data-name="Fill 1"
                                                            d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                            transform="translate(0 17)"
                                                            fill="#fff"
                                                        />
                                                        <g
                                                            id="Group_5"
                                                            data-name="Group 5"
                                                            transform="translate(9)"
                                                        >
                                                            <path
                                                                id="Clip_4-2"
                                                                data-name="Clip 4"
                                                                d="M0,0H2V16H0Z"
                                                                fill="none"
                                                            />
                                                            <g
                                                                id="Group_5-2"
                                                                data-name="Group 5"
                                                                clipPath="url(#clip-path)"
                                                            >
                                                                <path
                                                                    id="Fill_3"
                                                                    data-name="Fill 3"
                                                                    d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                                    fill="#fff"
                                                                />
                                                            </g>
                                                        </g>
                                                        <path
                                                            id="Fill_6"
                                                            data-name="Fill 6"
                                                            d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                            transform="translate(3 9)"
                                                            fill="#fff"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </TabAccordionPanel>

                                <TabAccordionPanel id="tab3">
                                    <div className="centered-content">
                                        <div className="eds-content cataplexy-content">
                                            <h3 className="black">
                                                What can cataplexy feel like?
                                            </h3>
                                            <p>
                                                <strong>Cataplexy</strong> can
                                                cause people with narcolepsy to
                                                collapse completely but more
                                                often occurs in specific areas
                                                of the body, like:
                                            </p>
                                            <Row className="row symptoms">
                                                <Col lg={4}>
                                                    <div className="card-icon">
                                                        <img
                                                            src={kneesIcon}
                                                            alt="Icon depicting knees buckling because of cataplexy"
                                                        />
                                                        <p className="font-20 asap asap-bold">
                                                            Knees buckling
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="card-icon">
                                                        <img
                                                            src={handsIcon}
                                                            alt="Icon depicting hand weakness because of cataplexy"
                                                        />
                                                        <p className="font-20 asap asap-bold">
                                                            Hand weakness
                                                            <span className="small-fnt font-16 acumen">
                                                                (dropping
                                                                objects)
                                                            </span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="card-icon">
                                                        <img
                                                            src={jawIcon}
                                                            alt="Icon depicting weakness in the jaw because of cataplexy"
                                                        />
                                                        <p className="font-20 asap asap-bold">
                                                            Jaw sagging
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p>
                                                People{" "}
                                                <strong>
                                                    may experience cataplexy in
                                                    different ways
                                                </strong>
                                                , like:
                                            </p>
                                            <ul className="list-dot">
                                                <li className="pr-10">
                                                    Muscle weakness or heaviness
                                                </li>
                                                <li>Going slack-jawed</li>
                                                <li className="pr-10">
                                                    "Spells" of muscle weakness
                                                </li>
                                                <li>Losing muscle control</li>
                                                <li className="pr-10">
                                                    Slurred speech
                                                </li>
                                                <li>Clumsiness</li>
                                                <li className="pr-10">
                                                    Drooping of the head, face,
                                                    or eyelids
                                                </li>
                                                <li>
                                                    Loss of grip or dropping
                                                    things
                                                </li>
                                            </ul>
                                            <span className="blue-text pro-bold font-18">
                                                It's not always obvious to
                                                people that these experiences of
                                                cataplexy are related to
                                                narcolepsy.
                                            </span>
                                            <div className="cta-block">
                                                <span className="font-21 asap asap-bold">
                                                    Download a questionnaire to
                                                    help you understand if you
                                                    could be experiencing
                                                    cataplexy and talk with your
                                                    healthcare provider about
                                                    your experiences and how
                                                    they may be affecting you.
                                                </span>
                                                <a
                                                    href="/pdf/Cataplexy-in-narcolepsy.pdf"
                                                    target="_blank"
                                                    style={{
                                                        paddingRight: "25px",
                                                        paddingLeft: "25px",
                                                    }}
                                                    rel="noopener noreferrer"
                                                    className="btn btn-primary btn-primary--md dwn-btn"
                                                >
                                                    Understand your cataplexy
                                                    <svg
                                                        id="download_arrow"
                                                        data-name="download arrow"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xlink="http://www.w3.org/1999/xlink"
                                                        width="21"
                                                        height="22"
                                                        viewBox="0 0 21 22"
                                                    >
                                                        <defs>
                                                            <clipPath id="clip-path">
                                                                <path
                                                                    id="Clip_4"
                                                                    data-name="Clip 4"
                                                                    d="M0,0H2V16H0Z"
                                                                    fill="none"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                        <path
                                                            id="Fill_1"
                                                            data-name="Fill 1"
                                                            d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                            transform="translate(0 17)"
                                                            fill="#fff"
                                                        />
                                                        <g
                                                            id="Group_5"
                                                            data-name="Group 5"
                                                            transform="translate(9)"
                                                        >
                                                            <path
                                                                id="Clip_4-2"
                                                                data-name="Clip 4"
                                                                d="M0,0H2V16H0Z"
                                                                fill="none"
                                                            />
                                                            <g
                                                                id="Group_5-2"
                                                                data-name="Group 5"
                                                                clipPath="url(#clip-path)"
                                                            >
                                                                <path
                                                                    id="Fill_3"
                                                                    data-name="Fill 3"
                                                                    d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                                    fill="#fff"
                                                                />
                                                            </g>
                                                        </g>
                                                        <path
                                                            id="Fill_6"
                                                            data-name="Fill 6"
                                                            d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                            transform="translate(3 9)"
                                                            fill="#fff"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </TabAccordionPanel>
                            </TabAccordion>
                        </div>
                    </div>
                </div>
            </section>

            <section className="block-cta two-col no-pb no-pt">
                <div className="col bg-lightBlue">
                    <div className="two-col__inner cta-flt-right blue-cta-spacer-rt">
                        {deviceSizes?.smDown ? (
                            <div
                                className="two-col__desc"
                                style={{ flexDirection: "column" }}
                            >
                                <div className="two-col__image two-col__icon">
                                    <img src={tipsIcon} alt="Clipboard icon" />
                                </div>
                                <div
                                    className="two-col__title two-col__title--alt text-white"
                                    style={{ textAlign: "center" }}
                                >
                                    <h3
                                        style={{
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                        }}
                                    >
                                        Narcolepsy shouldn’t <br /> stop you
                                        from living <br /> your life
                                    </h3>
                                    <div className="btn-wrap">
                                        <Link
                                            to="/living-with-narcolepsy/tips-for-living-with-narcolepsy"
                                            className="btn btn-primary btn-primary--md"
                                        >
                                            Find tips
                                            <TfiAngleRight />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="two-col__desc">
                                <div className="two-col__title two-col__title--alt text-white">
                                    <h3>
                                        Narcolepsy shouldn’t <br /> stop you
                                        from living <br /> your life
                                    </h3>
                                    <div className="btn-wrap">
                                        <Link
                                            to="/living-with-narcolepsy/tips-for-living-with-narcolepsy"
                                            className="btn btn-primary btn-primary--md"
                                        >
                                            Find tips
                                            <TfiAngleRight />
                                        </Link>
                                    </div>
                                </div>
                                <div className="two-col__image two-col__icon">
                                    <img src={tipsIcon} alt="Clipboard icon" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="cb" />
                </div>
                <div className="col bg-img">
                    <div className="two-col__inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={resourcesImg}
                                className="two-col"
                                alt="Chris, a real person with narcolepsy with cataplexy, sitting with friends outdoors"
                            />
                        ) : (
                            <img
                                src={resourcesMobileImg}
                                className="two-col"
                                alt="Chris, a real person with narcolepsy with cataplexy, sitting with friends outdoors"
                            />
                        )}
                        <div className="two-col__content cout-left-spacer">
                            <div className="two-col__title text-white has-textShadow">
                                <h3>
                                    Hear from real <br /> people living with{" "}
                                    <br /> narcolepsy
                                </h3>
                            </div>
                            <div className="btn-wrap m-spacer">
                                <Link
                                    to="/wakix-personal-stories"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    See their stories
                                    <TfiAngleRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialIconBlock />
        </HcpLayout>
    );
};

export default LivingWithNarcolepsy;

export const Head = () => (
    <Seo
        title="EDS and Cataplexy | WAKIX® (pitolisant)"
        description="Learn about the symptoms of excessive daytime sleepiness and cataplexy in adults with narcolepsy."
        keywords="what can EDS feel like, what can excessive daytime sleepiness feel like, what can cataplexy feel like, common symptoms of narcolepsy, what is cataplexy"
    />
);
